import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { List, Modal, Table, Tooltip, Spin, Checkbox, Switch } from "antd";
import {
  DownloadOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import {
  AttachmentIcon,
  PreviewIcon,
} from "../../components/icons/NovarcIcons";

import LoadingPage from "../../components/LoadingPage";
import Error404Page from "../../components/Error404Page";

import { VideoPlayer } from "../videos/VideosList";
import PreviewPDF from "../reports/PreviewPDF";

import { getRobotNovEyeLogs } from "../videos/g3LogsSlice";
import {
  getRobotVideos,
  setDiagnosticView,
  setDiagnosticViewFilter,
} from "../videos/videoSlice";
import {
  getNovDataLogContents,
  getRobotNovDataLogs,
} from "../reports/ndLogsSlice";
import { getRobotReports } from "../reports/reportSlice";
import {
  getRobotTier,
  updateRobotUsage,
  getRobotWeldFiles,
} from "./robotSlice";

import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import { normalizeRobotId } from "../../libs/utils";

import Calendar from "./Calendar";
import Moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";
import {
  findFilesByJobSpoolWeld,
  findVideosByTimestamp,
  getTimestampsFromVideoList,
  RunNumDiagnostics,
} from "./RunNumDiagnostics";
import {
  getDailyReport,
  getNovDataLogLink,
  getVideoLink,
  loadData,
} from "./robotSearchUtils";
import {
  combineLoadingProgress,
  useLoadingWithProgress,
} from "../../libs/useLoading";
import RobotFilesDayView from "./RobotFilesDayView";
import {
  formatDateAsFullText,
  formatDateAsHHmmss,
  formatDateAsYYYYMMDD,
} from "../../libs/utilsTyped";
import { groupWeldRunFiles } from "./videosAndReports/groupWeldRun";
import useRobotTimezone from "../../libs/useRobotTimezone";

const TOOLTIP_DOWNLOAD_LIMIT = (
  <span style={{ fontSize: "0.8rem" }}>
    {"Download limit exceeded"}
    <br />
    {
      "Sorry, you can't view or download at this time. Contact Novarc Customer Support to view file or increase limits"
    }
  </span>
);

const TOOLTIP_WARNING = (
  <span style={{ fontSize: "0.8rem" }}>
    {"Unable to find files"}
    <br />
    {
      "Sorry, you can't view or download at this time. Please try again or Contact Novarc Customer Support"
    }
  </span>
);

const MODAL_DELAY_IN_MS = 500;

const LOADING_TIMEOUT_SECS = 10;

// import { fileSize } from "../../libs/utils";

const moment = extendMoment(Moment);

function RobotSearchFiles(props) {
  const dispatch = useDispatch();
  // const [value, setValue] = useState("");
  // const [keyword, setKeyword] = useState("");

  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedJobDateRange, setselectedJobDateRange] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [modalDateOpen, setModalDateOpen] = useState(false);

  const [showTimeOut, setShowTimeOut] = useState(false);

  const { robotName } = useParams();
  const location = useLocation();

  const robotId = normalizeRobotId(robotName);

  const robotTimezone = useRobotTimezone(true);

  const [[robotWeldRunFiles, robotTier], robotLoading] = useLoadingWithProgress(
    "robot",
    (state) => [state.robotFiles?.[robotId], state.tier?.[robotId]],
    [getRobotWeldFiles, getRobotTier]
  );

  const [[videos, showDiagnosticView], videosLoading] = useLoadingWithProgress(
    "videos",
    (state) => [state.videos, state.showDiagnosticView],
    getRobotVideos
  );

  const [noveye_logs, noveyeLogsLoading] = useLoadingWithProgress(
    "noveye_logs",
    (state) => state.logs,
    getRobotNovEyeLogs
  );

  const [novdata_logs, novdataLogsLoading] = useLoadingWithProgress(
    "novdata_logs",
    (state) => state.logs,
    getRobotNovDataLogs
  );
  const [reports, reportsLoading] = useLoadingWithProgress(
    "reports",
    (state) => state.reports,
    getRobotReports
  );

  const allFilesLoading = combineLoadingProgress(
    videosLoading,
    noveyeLogsLoading,
    novdataLogsLoading,
    reportsLoading
  );
  if (allFilesLoading.isFinished() && !allFilesLoading.isSuccessful()) {
    console.error(
      "error while fetching files",
      videosLoading,
      noveyeLogsLoading,
      novdataLogsLoading,
      reportsLoading
    );
  }

  //this is used for the modal only
  const [selectedNovDataLog, novdataContentLoading] = useLoadingWithProgress(
    "novdata_logs",
    (state) => state.selectedItem,
    getNovDataLogContents
  );

  const setShowDiagnosticView = (value) => dispatch(setDiagnosticView(value));
  const { isCustomer } = props;

  const requestedDate = location.hash
    .split("/")
    .splice(-1)[0]
    .replace("#", "")
    .replace(/_/g, " ");

  useEffect(() => {
    dispatch(getRobotTier(robotId));
  }, [dispatch, robotId]);

  useEffect(() => {
    if (requestedDate) {
      if (moment(requestedDate, "YYYY-MM-DD").isValid()) {
        setSelectedDate(requestedDate);
        setModalDateOpen(true);
        return;
      }
    }
    if (selectedJobDateRange?.start || selectedJobDateRange?.end) {
      dispatch(getRobotVideos({ id: robotId, ...selectedJobDateRange }));
      dispatch(getRobotNovEyeLogs({ id: robotId, ...selectedJobDateRange }));
      dispatch(getRobotNovDataLogs({ id: robotId, ...selectedJobDateRange }));
      dispatch(getRobotReports({ id: robotId, ...selectedJobDateRange }));
    }

    let timer = setTimeout(
      () => setShowTimeOut(true),
      LOADING_TIMEOUT_SECS * 1000
    );

    return () => {
      clearTimeout(timer);
      //todo not sure if this is working
      setShowTimeOut(false);
    };
  }, [dispatch, robotId, requestedDate, selectedJobDateRange]);

  if (!robotId) {
    return <Error404Page />;
  }

  const current_files = {};

  const data = {
    videos,
    noveye_logs,
    reports,
    novdata_logs,
  };

  const { dates } = loadData(data, current_files);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleModalPreviewOpen = (item) => {
    setSelectedItem(item);
    handleUsageUpdate(robotId, { ...item, activity: "preview" });
    setModalPreviewOpen(true);
  };
  const handleModalPreviewClose = () => {
    setModalPreviewOpen(false);
  };

  const handleModalDateClose = () => {
    setModalDateOpen(false);
  };

  const handleUsageUpdate = (robotId, item) => {
    if (isCustomer) dispatch(updateRobotUsage({ id: robotId, payload: item }));
  };

  const groupedWeldRun = groupWeldRunFiles(robotWeldRunFiles, robotTimezone);

  const isLimitExceeded =
    robotTier &&
    parseInt(robotTier.used, 10) >= parseInt(robotTier.capacity, 10);

  const filteredWeldRun = (robotWeldRunFiles ?? [])
    .filter((item) =>
      // check if local calendar day (not UTC day) is the same
      moment(formatDateAsYYYYMMDD(item.arc_on_utc, item.time_zone)).isSame(
        selectedDate
      )
    )
    .sort((a, b) => {
      if (a.arc_on_utc > b.arc_on_utc) {
        return 1;
      }
      if (a.arc_on_utc < b.arc_on_utc) {
        return -1;
      } else {
        return 0;
      }
    });

  let dailyReports = [];
  if (selectedJob) {
    // filter all unique days (yyyy-mm-dd) from current job
    const dates = new Set(
      selectedJob.children.map((e) => e.arc_on_utc.substring(0, 10)).sort()
    );

    if (!!dates && dates.size > 1) {
      const datesArray = Array.from(dates);
      const start = datesArray[0];
      const end = datesArray.slice(-1)[0];
      const monthDiff = moment(end, "YYYY-MM").diff(
        moment(start, "YYYY-MM"),
        "months",
        true
      );

      if (!selectedJobDateRange && monthDiff > 0) {
        const range = { start, end };
        setselectedJobDateRange(range);
      }
    }
    // match filename to see if it contains a date in current job
    dailyReports = (reports ?? []).filter((report) => {
      for (const date of dates) {
        if (report.fileName.includes(date)) return true;
      }
      return false;
    });
  }

  const timestampsFromVideoList = getTimestampsFromVideoList(videos);

  function WeldFilesByJobSpool() {
    return (
      <>
        <div>
          {!isCustomer && (
            <Switch
              checkedChildren="Diagnostic view"
              unCheckedChildren="Customer view"
              onChange={() => setShowDiagnosticView(!showDiagnosticView)}
              checked={showDiagnosticView}
            />
          )}
          {showDiagnosticView && (
            <>
              <p>
                You can check the status of each weld to see if Video and Log
                files are correctly uploaded.
              </p>
              <p>
                If there is an error, expand the table with the + buttons on the
                left to see the full list of files available for each weld.
                (scroll down if needed)
              </p>
              <p>
                If there are more files than expected, there may be misordered
                videos because of cold runs. If there are less files than
                expected, the customer might not have uploaded all files from
                the SWR yet.
              </p>
            </>
          )}
        </div>
        <br />
        <div>{showDiagnosticView ? <Diagnostic /> : <CustomerView />}</div>
      </>
    );

    function Diagnostic() {
      const diagnosticFilterState = useSelector(
        (state) => state.videos.diagnosticViewFilter
      );
      const options = Object.keys(diagnosticFilterState);
      const checkedList = options.filter((k) => diagnosticFilterState[k]);
      const dispatch = useDispatch();
      const showReports = checkedList.includes("Show Reports");
      return (
        <>
          <Checkbox.Group
            options={options}
            value={checkedList}
            onChange={(newCheckedOptions) => {
              const filters = options.reduce(
                (prev, curr, i) => ({
                  ...prev,
                  [curr]: newCheckedOptions.includes(curr),
                }),
                {}
              );
              dispatch(setDiagnosticViewFilter(filters));
            }}
          />
          {showReports && <DailyReportsDownloadList />}
          <RunNumDiagnostics
            videos={videos}
            isLimitExceeded={isLimitExceeded}
            isCustomer={isCustomer}
            TOOLTIP_MSG={TOOLTIP_DOWNLOAD_LIMIT}
            handleModalPreviewOpen={handleModalPreviewOpen}
            DownloadButton={DownloadButton}
            robotId={robotId}
            handleUsageUpdate={handleUsageUpdate}
            showTimeOut={showTimeOut}
            TOOLTIP_WARNING={TOOLTIP_WARNING}
            currentWeldData={selectedJob.children}
            novdata_logs={novdata_logs}
            showViews={checkedList}
          />
        </>
      );
      function DailyReportsDownloadList() {
        return (
          <List>
            {dailyReports.map((item) => {
              const reportDate =
                item && item.fileName.match(/(20\d{2}-\d{2}-\d{2})/)?.[0];
              if (reportDate) {
                return (
                  <List.Item
                    style={{ display: "flex", justifyContent: "right" }}
                    actions={[
                      <span
                        style={{
                          color: "#1890ff",
                          fontWeight: 600,
                          border: "1px solid #F0F0F0",
                          padding: "7px 8px",
                          cursor:
                            isLimitExceeded && isCustomer
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          isLimitExceeded && isCustomer
                            ? false
                            : handleModalPreviewOpen(item)
                        }
                      >
                        <Tooltip
                          title={
                            isLimitExceeded && isCustomer
                              ? TOOLTIP_DOWNLOAD_LIMIT
                              : null
                          }
                        >
                          <PreviewIcon
                            style={{
                              color: "#1890ff",
                              cursor:
                                isLimitExceeded && isCustomer
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                        </Tooltip>{" "}
                        Preview
                      </span>,
                      <span>
                        <a
                          style={{
                            color: "#fff",
                            backgroundColor: "#3076B8",
                            padding: "7px 8px",
                          }}
                          href={isLimitExceeded && isCustomer ? null : item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            title={
                              isLimitExceeded && isCustomer
                                ? TOOLTIP_DOWNLOAD_LIMIT
                                : null
                            }
                          >
                            <DownloadOutlined
                              style={{
                                color: "#fff",
                                paddingRight: 4,
                                cursor:
                                  isLimitExceeded && isCustomer
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() =>
                                isLimitExceeded && isCustomer
                                  ? null
                                  : handleUsageUpdate(robotId, item)
                              }
                            />
                          </Tooltip>
                          Download
                        </a>
                      </span>,
                    ]}
                  >
                    <span style={{ fontWeight: 600, cursor: "none" }}>
                      <Tooltip
                        title={
                          isLimitExceeded && isCustomer
                            ? TOOLTIP_DOWNLOAD_LIMIT
                            : null
                        }
                      >
                        <AttachmentIcon
                          style={{
                            cursor:
                              isLimitExceeded && isCustomer
                                ? "not-allowed"
                                : "none",
                          }}
                        />
                      </Tooltip>{" "}
                      Daily Report {reportDate}
                    </span>
                  </List.Item>
                );
              }
              console.warn(
                "WeldFilesByJobSpool: invalid entry in daily reports",
                item
              );
              return null;
            })}
          </List>
        );
      }
    }
    function CustomerView() {
      return (
        <List>
          {dailyReports.map((item) => {
            if (item) {
              const reportDate = item.fileName.match(/(\d{4}-\d{2}-\d{2})/);

              return (
                <List.Item
                  actions={[
                    <span style={{ fontWeight: 600, cursor: "none" }}>
                      <Tooltip
                        title={
                          isLimitExceeded && isCustomer
                            ? TOOLTIP_DOWNLOAD_LIMIT
                            : null
                        }
                      >
                        <AttachmentIcon
                          style={{
                            cursor:
                              isLimitExceeded && isCustomer
                                ? "not-allowed"
                                : "none",
                          }}
                        />
                      </Tooltip>{" "}
                      Daily Report {reportDate ? reportDate[0] : ""}
                    </span>,
                    item ? (
                      <span
                        style={{
                          color: "#1890ff",
                          fontWeight: 600,
                          border: "1px solid #F0F0F0",
                          padding: "7px 8px",
                          cursor:
                            isLimitExceeded && isCustomer
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() =>
                          isLimitExceeded && isCustomer
                            ? null
                            : handleModalPreviewOpen(item)
                        }
                      >
                        <Tooltip
                          title={
                            isLimitExceeded && isCustomer
                              ? TOOLTIP_DOWNLOAD_LIMIT
                              : null
                          }
                        >
                          <PreviewIcon
                            style={{
                              color: "#1890ff",
                              cursor:
                                isLimitExceeded && isCustomer
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                        </Tooltip>{" "}
                        Preview
                      </span>
                    ) : showTimeOut ? (
                      <Tooltip title={TOOLTIP_WARNING}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    ) : (
                      <LoadingOutlined />
                    ),

                    item ? (
                      <span>
                        <a
                          style={{
                            color: "#fff",
                            backgroundColor: "#3076B8",
                            padding: "7px 8px",
                          }}
                          href={isLimitExceeded && isCustomer ? null : item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            title={
                              isLimitExceeded && isCustomer
                                ? TOOLTIP_DOWNLOAD_LIMIT
                                : null
                            }
                          >
                            <DownloadOutlined
                              style={{
                                color: "#fff",
                                paddingRight: 4,
                                cursor:
                                  isLimitExceeded && isCustomer
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() =>
                                isLimitExceeded && isCustomer
                                  ? null
                                  : handleUsageUpdate(robotId, item)
                              }
                            />
                          </Tooltip>
                          Download
                        </a>
                      </span>
                    ) : showTimeOut ? (
                      <Tooltip title={TOOLTIP_WARNING}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    ) : (
                      <LoadingOutlined />
                    ),
                  ]}
                >
                  <div>
                    <span> </span>
                  </div>
                </List.Item>
              );
            }
          })}

          <Table
            columns={[
              {
                title: "Start Time",
                key: "arc_on_utc",
                // dataIndex: "",
                render: (_, item) => {
                  return (
                    <span>
                      {formatDateAsFullText(
                        item.arc_on_utc,
                        item.time_zone,
                        true
                      )}
                    </span>
                  );
                },
              },
              {
                title: "End Time",
                key: "arc_off_utc",
                // dataIndex: "",
                render: (_, item) => {
                  return (
                    <span>
                      {formatDateAsHHmmss(item.arc_off_utc, item.time_zone)}
                    </span>
                  );
                },
              },
              {
                title: "Weld Name",
                key: "weld_info",
                // dataIndex: "run_number",
                render: (_, item) => {
                  return (
                    <span>{`${item.work_order_ref} ${item.spool_ref} ${item.welder_id_ref}`}</span>
                  );
                },
              },
              {
                title: "Run",
                key: "run_number",
                dataIndex: "run_number",
                // render: (text) => <span>{text}</span>,
              },
              // {
              //   title: "Upload Time",
              //   key: "",
              //   dataIndex: "",
              //   render: (text) => <span>{text}</span>,
              // },
              {
                title: "Operator",
                key: "operator_name",
                dataIndex: "operator_name",
                // render: (text) => <span>{text}</span>,
              },
              {
                title: "Videos",
                key: "videos",
                // dataIndex: "",
                render: (_, { video, ...weld }) =>
                  weld?.vid_filename === null ? (
                    <Tooltip title="No video file linked to this weld run">
                      <CloseOutlined />
                    </Tooltip>
                  ) : video ? (
                    <span style={{ fontSize: 24 }}>
                      <Tooltip
                        title={
                          isLimitExceeded && isCustomer
                            ? TOOLTIP_DOWNLOAD_LIMIT
                            : "Click to preview"
                        }
                      >
                        <PreviewIcon
                          style={{
                            color: "#1890ff",
                            cursor:
                              isLimitExceeded && isCustomer
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={() =>
                            isLimitExceeded && isCustomer
                              ? null
                              : // : isNovEyeH265 && !(isEdge || isSafari)
                                // ? message.error(WARNING, 5)
                                handleModalPreviewOpen(video)
                          }
                        />
                      </Tooltip>{" "}
                      <DownloadButton
                        isLimitExceeded={isLimitExceeded && isCustomer}
                        robotId={robotId}
                        item={video}
                        handleUsageUpdate={handleUsageUpdate}
                        className="download-button-big"
                      />
                    </span>
                  ) : showTimeOut ? (
                    <LostFileTooltip
                      type="videos"
                      runNumDiagnostic={findFilesByJobSpoolWeld(
                        videos,
                        weld,
                        "Video"
                      )}
                      timestampDiagnostic={findVideosByTimestamp(
                        timestampsFromVideoList,
                        weld
                      )}
                    />
                  ) : (
                    <LoadingOutlined />
                  ),
              },
              {
                title: "Logs",
                key: "logs",
                // dataIndex: "",
                render: (_, { tsv, ...weld }) =>
                  weld?.log_filename === null ? (
                    <Tooltip title="No NovData log file linked to this weld run">
                      <CloseOutlined />
                    </Tooltip>
                  ) : tsv ? (
                    <span style={{ fontSize: 24 }}>
                      <DownloadButton
                        isLimitExceeded={isLimitExceeded && isCustomer}
                        robotId={robotId}
                        item={tsv}
                        handleUsageUpdate={handleUsageUpdate}
                        className="download-button-big"
                      />
                    </span>
                  ) : showTimeOut ? (
                    <LostFileTooltip
                      type="logs"
                      runNumDiagnostic={findFilesByJobSpoolWeld(
                        novdata_logs,
                        weld
                      )}
                    />
                  ) : (
                    <LoadingOutlined />
                  ),
              },
            ]}
            dataSource={selectedJob.children.map((item, i) => {
              const video = getVideoLink(videos, item);
              const tsv = getNovDataLogLink(novdata_logs, item);

              return { ...item, video, tsv, key: i };
            })}
          ></Table>
        </List>
      );
    }
  }

  return (
    <Spin
      spinning={!robotLoading.isFinished()}
      indicator={
        <LoadingPage
          size={LOADING_ANIMATION_SIZE_FULL}
          progress={robotLoading}
        />
      }
    >
      <Calendar
        className="daily-reports"
        onDateChange={(calendarStart, calendarEnd) => {
          const range = { start: calendarStart, end: calendarEnd };
          dispatch(getRobotWeldFiles({ id: robotId, ...range }));
          dispatch(getRobotVideos({ id: robotId, ...range }));
          dispatch(getRobotNovEyeLogs({ id: robotId, ...range }));
          dispatch(getRobotNovDataLogs({ id: robotId, ...range }));
          dispatch(getRobotReports({ id: robotId, ...range }));
        }}
        robotId={robotId}
        events={groupedWeldRun}
        eventClick={(info) => {
          info.jsEvent.preventDefault();
          setModalOpen(true);
          dispatch(getRobotTier(robotId));
          setSelectedJob(info.event.extendedProps);
        }}
        onDateClick={(date) => {
          setModalDateOpen(true);
          dispatch(getRobotTier(robotId));
          const setDate = moment.utc(date.toUTCString()).format("YYYY-MM-DD");
          setSelectedDate(setDate);
        }}
        datePickerEvents={dates}
        // not working because the dispatch is depending on the onDateChange to run
        // loadingProgress={allFilesLoading}
      />
      {!!selectedJob && (
        <Modal
          className="WeldVideos_Modal"
          title={`${selectedJob.work_order_ref} ${selectedJob.spool_ref} (${selectedJob.children.length}) runs`}
          visible={modalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          footer={null}
        >
          <WeldFilesByJobSpool />
        </Modal>
      )}
      {!!selectedDate && !isCustomer && (
        <RobotFilesDayView
          selectedDate={selectedDate}
          modalDateOpen={modalDateOpen}
          handleModalDateClose={handleModalDateClose}
          reports={reports ?? []}
          isLimitExceeded={isLimitExceeded}
          isCustomer={isCustomer}
          TOOLTIP_DOWNLOAD_LIMIT={TOOLTIP_DOWNLOAD_LIMIT}
          handleModalPreviewOpen={handleModalPreviewOpen}
          showTimeOut={showTimeOut}
          TOOLTIP_WARNING={TOOLTIP_WARNING}
          handleUsageUpdate={handleUsageUpdate}
          robotId={robotId}
          DownloadButton={DownloadButton}
          videos={videos ?? []}
          novdata_logs={novdata_logs ?? []}
          filteredWeldRun={filteredWeldRun ?? []}
        />
      )}
      {!!selectedDate && isCustomer && (
        <Modal
          className="WeldVideos_Modal"
          title={moment(selectedDate).format("dddd, MMMM DD")}
          visible={modalDateOpen}
          // onOk={handleModalDateClose}
          onCancel={handleModalDateClose}
          destroyOnClose={true}
          footer={null}
        >
          <List>
            {[getDailyReport(reports, { arc_on_utc: selectedDate })].map(
              (item) => {
                if (item) {
                  return (
                    <List.Item
                      actions={[
                        <span style={{ fontWeight: 600, cursor: "none" }}>
                          <Tooltip
                            title={
                              isLimitExceeded && isCustomer
                                ? TOOLTIP_DOWNLOAD_LIMIT
                                : null
                            }
                          >
                            <AttachmentIcon
                              style={{
                                cursor:
                                  isLimitExceeded && isCustomer
                                    ? "not-allowed"
                                    : "none",
                              }}
                            />
                          </Tooltip>{" "}
                          Daily Report
                        </span>,
                        item ? (
                          <span
                            style={{
                              color: "#1890ff",
                              fontWeight: 600,
                              border: "1px solid #F0F0F0",
                              padding: "7px 8px",
                              cursor:
                                isLimitExceeded && isCustomer
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={() =>
                              isLimitExceeded && isCustomer
                                ? null
                                : handleModalPreviewOpen(item)
                            }
                          >
                            <Tooltip
                              title={
                                isLimitExceeded && isCustomer
                                  ? TOOLTIP_DOWNLOAD_LIMIT
                                  : null
                              }
                            >
                              <PreviewIcon
                                style={{
                                  color: "#1890ff",
                                  cursor:
                                    isLimitExceeded && isCustomer
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              />
                            </Tooltip>{" "}
                            Preview
                          </span>
                        ) : showTimeOut ? (
                          <Tooltip title={TOOLTIP_WARNING}>
                            <ExclamationCircleOutlined />
                          </Tooltip>
                        ) : (
                          <LoadingOutlined />
                        ),

                        item ? (
                          <span>
                            <a
                              style={{
                                color: "#fff",
                                backgroundColor: "#3076B8",
                                padding: "7px 8px",
                              }}
                              href={
                                isLimitExceeded && isCustomer ? null : item.url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Tooltip
                                title={
                                  isLimitExceeded && isCustomer
                                    ? TOOLTIP_DOWNLOAD_LIMIT
                                    : null
                                }
                              >
                                <DownloadOutlined
                                  style={{
                                    color: "#fff",
                                    paddingRight: 4,
                                    cursor:
                                      isLimitExceeded && isCustomer
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={() =>
                                    isLimitExceeded && isCustomer
                                      ? null
                                      : handleUsageUpdate(robotId, item)
                                  }
                                />
                              </Tooltip>
                              Download
                            </a>
                          </span>
                        ) : showTimeOut ? (
                          <Tooltip title={TOOLTIP_WARNING}>
                            <ExclamationCircleOutlined />
                          </Tooltip>
                        ) : (
                          <LoadingOutlined />
                        ),
                      ]}
                    >
                      <div>
                        <span> </span>
                      </div>
                    </List.Item>
                  );
                } else {
                  return (
                    <List.Item>
                      No Daily report found on {selectedDate}
                    </List.Item>
                  );
                }
              }
            )}

            <Table
              columns={[
                {
                  title: "Start Time",
                  key: "arc_on_utc",
                  // dataIndex: "",
                  render: (_, item) => {
                    return (
                      <span>
                        {formatDateAsFullText(
                          item.arc_on_utc,
                          item.time_zone,
                          true
                        )}
                      </span>
                    );
                  },
                },
                {
                  title: "End Time",
                  key: "arc_off_utc",
                  // dataIndex: "",
                  render: (_, item) => {
                    return (
                      <span>
                        {formatDateAsHHmmss(item.arc_off_utc, item.time_zone)}
                      </span>
                    );
                  },
                },
                {
                  title: "Weld Name",
                  key: "weld_info",
                  // dataIndex: "run_number",
                  render: (_, item) => {
                    return (
                      <span>{`${item.work_order_ref} ${item.spool_ref} ${item.welder_id_ref}`}</span>
                    );
                  },
                },
                {
                  title: "Run",
                  key: "run_number",
                  dataIndex: "run_number",
                  // render: (text) => <span>{text}</span>,
                },
                // {
                //   title: "Upload Time",
                //   key: "",
                //   dataIndex: "",
                //   render: (text) => <span>{text}</span>,
                // },
                {
                  title: "Operator",
                  key: "operator_name",
                  dataIndex: "operator_name",
                  // render: (text) => <span>{text}</span>,
                },
                {
                  title: "Videos",
                  key: "videos",
                  // dataIndex: "",
                  render: (_, { video, ...weld }) =>
                    video ? (
                      <span style={{ fontSize: 24 }}>
                        <Tooltip
                          title={
                            isLimitExceeded && isCustomer
                              ? TOOLTIP_DOWNLOAD_LIMIT
                              : "Click to preview"
                          }
                        >
                          <PreviewIcon
                            style={{
                              color: "#1890ff",
                              cursor:
                                isLimitExceeded && isCustomer
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={() =>
                              isLimitExceeded && isCustomer
                                ? null
                                : // : isNovEyeH265 && !(isEdge || isSafari)
                                  // ? message.error(WARNING, 5)
                                  handleModalPreviewOpen(video)
                            }
                          />
                        </Tooltip>{" "}
                        <DownloadButton
                          isLimitExceeded={isLimitExceeded && isCustomer}
                          robotId={robotId}
                          item={video}
                          handleUsageUpdate={handleUsageUpdate}
                          className="download-button-big"
                        />
                      </span>
                    ) : showTimeOut ? (
                      <LostFileTooltip
                        type="videos"
                        runNumDiagnostic={findFilesByJobSpoolWeld(
                          videos,
                          weld,
                          "Video"
                        )}
                        timestampDiagnostic={findVideosByTimestamp(
                          timestampsFromVideoList,
                          weld
                        )}
                      />
                    ) : (
                      <LoadingOutlined />
                    ),
                },
                {
                  title: "Logs",
                  key: "logs",
                  // dataIndex: "",
                  render: (_, { tsv, ...weld }) =>
                    tsv ? (
                      <span style={{ fontSize: 24 }}>
                        <DownloadButton
                          isLimitExceeded={isLimitExceeded && isCustomer}
                          robotId={robotId}
                          item={tsv}
                          handleUsageUpdate={handleUsageUpdate}
                          className="download-button-big"
                        />
                      </span>
                    ) : showTimeOut ? (
                      <LostFileTooltip
                        type="logs"
                        runNumDiagnostic={findFilesByJobSpoolWeld(
                          novdata_logs,
                          weld
                        )}
                      />
                    ) : (
                      <LoadingOutlined />
                    ),
                },
              ]}
              dataSource={filteredWeldRun.map((item) => {
                const video = getVideoLink(videos, item);
                const tsv = getNovDataLogLink(novdata_logs, item);

                return { ...item, video, tsv };
              })}
            ></Table>
          </List>
        </Modal>
      )}

      {!!selectedItem && (
        <Modal
          className="WeldVideos_Modal"
          open={modalPreviewOpen}
          centered
          bodyStyle={{ height: "80vh" }}
          width={"70%"}
          onOk={handleModalPreviewClose}
          onCancel={handleModalPreviewClose}
          destroyOnClose={true}
        >
          <DisplayFile
            item={{
              ...selectedItem,
              name: selectedItem.fileName,
              download: selectedItem.url,
            }}
            // selectedNovEyeLog={selectedNovEyeLog}
            selectedNovDataLog={selectedNovDataLog}
          />
        </Modal>
      )}
    </Spin>
  );
}

function LostFileTooltip(props) {
  const { type, runNumDiagnostic = [], timestampDiagnostic = [] } = props;
  const runNumDiagnosticMsg = runNumDiagnostic.length ? (
    <>
      <p>
        Warning: Found a different number of {type} than num of runs for this
        weld.
      </p>
      <p>
        Found {runNumDiagnostic.length} {type} for this weld
      </p>
    </>
  ) : null;
  const timestampDiagnosticMsg = timestampDiagnostic.length ? (
    <>
      <p>
        Warning: Found {timestampDiagnostic.length} unnamed videos on the same
        day
      </p>
      <p>
        This may be due to NovEye error or invalid filename input by operator.
        Check the Search Video page to identify unnamed videos on this date
      </p>
    </>
  ) : null;
  const noDiagnosticsFound =
    !runNumDiagnostic.length && !timestampDiagnostic.length;
  const fileMissingMsg = noDiagnosticsFound ? (
    <p>
      The {type} may have failed to be generated on the SWR, or failed to be
      uploaded to Cloud
    </p>
  ) : null;
  return (
    <Tooltip
      title={
        <span style={{ fontSize: "0.8rem" }}>
          <p>Unable to find files</p>
          {runNumDiagnosticMsg}
          {timestampDiagnosticMsg}
          {fileMissingMsg}
        </span>
      }
    >
      {noDiagnosticsFound ? (
        <ExclamationCircleOutlined />
      ) : (
        <QuestionCircleOutlined />
      )}
    </Tooltip>
  );
}

const DisplayFile = ({ item, selectedNovDataLog, selectedNovEyeLog }) => {
  const extension = item && item.fileName && item.fileName.split(".");
  const type = extension[extension.length - 1];
  switch (type) {
    case "mp4":
      return (
        <VideoPlayer
          item={item}
          searchText={item.fileName}
          truncatedVideoName={item.fileName}
        ></VideoPlayer>
      );

    case "pdf":
      return <PreviewPDF item={{ ...item, download: item.url }}></PreviewPDF>;

    case "log":
      return <span style={{ whiteSpace: "pre" }}>{selectedNovEyeLog}</span>;

    case "tsv":
      return <span style={{ whiteSpace: "pre" }}>{selectedNovDataLog}</span>;
    default:
      return null;
  }
};

function DownloadButton(props) {
  const { isLimitExceeded, item, robotId, handleUsageUpdate, className } =
    props;
  const { fileName, url, size } = item;
  return (
    <>
      <Tooltip title={isLimitExceeded ? TOOLTIP_DOWNLOAD_LIMIT : "Download"}>
        <a
          className={"videos-reports-download " + className}
          title={fileName}
          href={isLimitExceeded ? null : url}
          target="_blank"
          rel="noopener noreferrer"
          download
          type="application/octet-stream"
        >
          <DownloadOutlined
            style={{
              cursor: isLimitExceeded ? "not-allowed" : "pointer",
            }}
            onClick={() =>
              isLimitExceeded ? null : handleUsageUpdate(robotId, item)
            }
          />
          <span className="file-size"> {formatFileSize(size)}</span>
        </a>
      </Tooltip>
    </>
  );
}

function formatFileSize(bytes) {
  if (bytes / 1024 < 1000) {
    return `${(bytes / 1024).toFixed(1)}kB`;
  }
  if (bytes / (1024 * 1024) < 1000) {
    return `${(bytes / (1024 * 1024)).toFixed(1)}MB`;
  } else {
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)}GB`;
  }
}

export default RobotSearchFiles;
