import axios from "axios";

import {API_USERS_ROBOTS} from "../../libs/constants";
import { getToken } from "../../libs/auth";
import { normalizeRobotId } from "../../libs/utils";

/**
 * Private boilerplate function for a basic GET request
 * for a single report item from a specific robot
 * @param path:string not null
 * @param id:string not null
 * @param query:(string | string[][] | Record<string, string> | URLSearchParams | undefined)
 * @returns AxiosPromise<any>
 */
async function getRobotItem(path, id, query) {
  const queryString = new URLSearchParams(query).toString();

  const url = `${API_USERS_ROBOTS}/${id}/${path}?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

// ##############################
// For all of accessible Robots
// ##############################
export async function getRobots(payload) {
  const url = `${API_USERS_ROBOTS}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      // temp monkeypatching - /robots api sends 1805 in the name field instead of the ID field
      const swr1805 = data.find((e) => e.name === "SWR-1805");
      if (swr1805) swr1805.name = "SWR-185";

      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getGeolocation() {
  const url = `${API_USERS_ROBOTS}/geolocation`;

  const options = {
    method: "get",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };

  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}


export async function getOperationHours(payload) {
  const url = `${API_USERS_ROBOTS}/operation_hours`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getPerformanceWeldHours(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/performance/weld_hours?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getPerformanceWeldInches(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/performance/weld_inches?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getPerformanceOperatorsWeldHours(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/performance/operators_weld_hours?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getPerformanceOperatorsWeldInches(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/performance/operators_weld_inches?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getPerformanceWithoutWeldHours(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/performance/inactive?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getStatisticsPipeSize(payload, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_USERS_ROBOTS}/statistics/pipe_size?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

// ##############################
// For Individual Robot
// ##############################
export async function getProgramData(id, query) {
  const idNum = normalizeRobotId(id);

  // const { key, start, end } = query;
  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/program_data?${queryString}`;

  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
export async function getRobotAlarms(id, query) {
  const idNum = normalizeRobotId(id);

  // const { start, end } = query;
  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/alarms?${queryString}`;

  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}
export async function getRobotTimezone(id, query) {
  const idNum = normalizeRobotId(id);

  const url = `${API_USERS_ROBOTS}/${idNum}/timezone`;

  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function getWeldRuns(id, query) {
  const idNum = normalizeRobotId(id);

  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/weld_run?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getLastWeldRun(id) {
  const idNum = normalizeRobotId(id);

  const url = `${API_USERS_ROBOTS}/${idNum}/weld_run_last`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function getWeldRunFiles(id, query) {
  const idNum = normalizeRobotId(id);

  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/weld_run_files?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function putWeldFiles(robot_id, body) {
  const idNum = normalizeRobotId(robot_id);

  const url = `${API_USERS_ROBOTS}/${idNum}/weld_files`;
  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: body,
  };

  const { data, status } = await axios(options);

  if (status === 200) {
    return data;
  } else {
    throw new Error(status);
  }
}

export async function getRobotPerformanceWeldHours(id, query) {
  const idNum = normalizeRobotId(id);

  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/performance/weld_hours?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
export async function getRobotPerformanceWeldInches(id, query) {
  const idNum = normalizeRobotId(id);

  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/performance/weld_inches?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
export async function getRobotPerformanceOperatorsWeldHours(params, query) {
  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${params}/performance/operators_weld_hours?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
export async function getRobotPerformanceOperatorsWeldInches(params, query) {
  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${params}/performance/operators_weld_inches?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getTier(id) {
  const idNum = normalizeRobotId(id);
  const url = `${API_USERS_ROBOTS}/${idNum}/bandwidth`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

// fixme this should not be sent by the user / frontend
export async function updateUsage(id, payload) {
  const idNum = normalizeRobotId(id);
  const url = `${API_USERS_ROBOTS}/${idNum}/bandwidth`;
  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: payload,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getUsage(id, query) {
  const idNum = normalizeRobotId(id);

  const queryString = new URLSearchParams(query).toString();
  const url = `${API_USERS_ROBOTS}/${idNum}/bandwidth/usage?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getRobotWeldTestConsent(id) {
  const idNum = normalizeRobotId(id);

  const url = `${API_USERS_ROBOTS}/${idNum}/weld_test_consents`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function putRobotWeldTestConsent(id, payload) {
  const idNum = normalizeRobotId(id);
  const url = `${API_USERS_ROBOTS}/${idNum}/weld_test_consents/`;
  const options = {
    method: "PUT",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getWeldTestStatus(params, query) {
  return getRobotItem("weld_test_status", params, query);
}

export async function getWeldTestReports(params, query) {
  return getRobotItem("weld_test_reports", params, query);
}

export async function createWeldTestReport(params, payload) {
  console.log(payload);
  const url = `${API_USERS_ROBOTS}/${params}/weld_test_reports`;
  const options = {
    method: "POST",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getWeldTestReport(params, query) {
  const queryString = new URLSearchParams(query).toString();

  const url = `${API_USERS_ROBOTS}/${params.robot_id}/weld_test_reports/${params.report_id}?${queryString}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function updateWeldTestReport(params, payload) {
  const url = `${API_USERS_ROBOTS}/${params.robot_id}/weld_test_reports/${params.report_id}`;
  const options = {
    method: "PUT",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function deleteWeldTestReport(params) {
  const url = `${API_USERS_ROBOTS}/${params.robot_id}/weld_test_reports/${params.report_id}`;
  const options = {
    method: "DELETE",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function getNotificationsOnRobot(params) {
  const url = `${API_USERS_ROBOTS}/${params.robot_id}/notifications`;
  const options = {
    method: "GET",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function createNotificationOnRobot(params, payload) {
  const url = `${API_USERS_ROBOTS}/${params.robot_id}/notifications`;
  const options = {
    method: "POST",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function updateNotificationOnRobot(params, payload) {
  const url = `${API_USERS_ROBOTS}/${params.robot_id}/notifications/${params.notification_id}`;
  const options = {
    method: "PUT",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
