import { utcToLocal } from "../../../libs/utilsTyped";
import { chain, orderBy } from "lodash";
import moment from "moment-timezone";
import { convertInchesTomm } from "../../../libs/utils";

export function groupWeldRunFiles(
  robotWeldRunFiles: any[],
  robotTimezone: string,
): any[] {
  return chain(robotWeldRunFiles)
    .map(makeCountDurationMap(robotTimezone))
    .groupBy((item) => {
      // group events as part of the same weld if they have the same Job+Spool ID
      return `${item.work_order_ref} ${item.spool_ref}`;
    })
    .entries()
    .map(([groupKey, weld]) => {
      const children = orderBy([...weld], ["arc_on_utc"], ["desc"]);

      const childrenSize = children.length;
      const first = children[0];
      const last = children[childrenSize - 1];

      return {
        key: groupKey,
        children,
        title: groupKey,
        time_zone: robotTimezone,
        start: utcToLocal(last.arc_on_utc, robotTimezone)
          .utc(true)
          .toISOString(),
        end: utcToLocal(first.arc_off_utc, robotTimezone)
          .utc(true)
          .toISOString(),
        work_order_ref: last.work_order_ref,
        spool_ref: last.spool_ref,

        //todo remove
        units_system: last.units_system,
        pipe_size_inches: last.pipe_size_inches,
        pipe_schedule: last.pipe_schedule,

        // sum of arc off time (setup time) on grouped weld
        duration: children.slice(1).reduce((prev, curr) => {
          return prev.add(curr.duration);
        }, moment.duration(first.duration)),

        // sum of weld time on grouped weld
        weld_time: children.slice(1).reduce((prev, curr) => {
          return prev.add(curr.weld_time);
        }, moment.duration(first.weld_time))._data,

        //needed?
        weld_inches: last.run_number === 0 ? last.weld_inches : null,
      };
    })
    .value();
}

export function groupWeldRunAnalytics(
  robotWeldRun: any[],
  robotTimezone: string,
  isMetric: boolean,
): any[] {
  return chain(robotWeldRun)
    .map(makeCountDurationMap(robotTimezone))
    .map((item) => {
      return {
        ...item,

        pipe_size_inches: isMetric
          ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
          : item.pipe_size_inches,
      };
    })
    .groupBy((item) => {
      return `${item.work_order_ref} ${item.spool_ref}`;
    })
    .entries()
    .map(([work_order_spool, weld]) => {
      const items = orderBy([...weld], ["run_number"], ["desc"]);

      const childrenSize = items.length;
      const first = items[0];
      const last = items[childrenSize - 1];

      return {
        key: work_order_spool,
        items,
        time_zone: last.time_zone,
        arc_on_utc: last.arc_on_utc,
        arc_off_utc: first.arc_off_utc,
        work_order_ref: last.work_order_ref,
        spool_ref: last.spool_ref,

        units_system: last.units_system,
        pipe_size_inches: last.pipe_size_inches,
        pipe_schedule: last.pipe_schedule,

        // sum of arc off time (setup time) on grouped weld
        duration: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.duration);
        }, moment.duration(first.duration)),

        // sum of weld time on grouped weld
        weld_time: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.weld_time);
        }, moment.duration(first.weld_time))._data,

        // sum of weld inches with more than 1 min weld time
        weld_inches: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_inches);
          }, 0)
          .toFixed(1),

        // sum of weld fdi with more than 1 min weld time
        weld_fdi: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_fdi);
          }, 0)
          .toFixed(1),

        // sum of weld di with more than 1 min weld time
        weld_di: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_di);
          }, 0)
          .toFixed(1),
      };
    })
    .value();
}
function makeCountDurationMap(robotTimezone: string) {
  return function countDuration(currRun, idx, list) {
    const prevRun = idx < list.length - 1 && list[idx + 1];
    if (!prevRun) {
      return { ...currRun };
    }
    const currRunStartTime = moment.tz(currRun.arc_on_utc, robotTimezone);
    const nextRunStartTime = moment.tz(prevRun.arc_on_utc, robotTimezone);
    const prevRunEndTime = moment.tz(prevRun.arc_off_utc, robotTimezone);
    if (nextRunStartTime.dayOfYear() === currRunStartTime.dayOfYear()) {
      const duration = moment.duration(currRunStartTime.diff(prevRunEndTime));

      return { ...currRun, duration };
    } else {
      return { ...currRun };
    }
  };
}
